<template>
  <div>
    <v-dialog v-model="dialog" persistent :overlay="false" max-width="600px" transition="dialog-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" v-show="modulePermission('mjo', 'create')" @click="getData(true)">Add</v-btn>
      </template>
      <v-card color="" :loading="loading" min-height="250">
        <v-card-title>Select Document Template</v-card-title>
        <v-card-text>
          <v-text-field v-model="search" dense flat hide-details outlined placeholder="Search" clearable></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pa-0" style="max-height: 300px; overflow-y: auto;">
          <v-list class="pb-4 px-2" three-line>
            <v-list-item-group v-model="selectedDocument">
              <v-list-item v-for="(item, i) in items" :key="i" class="d-block py-2" style="border-bottom: 1px solid #ddd">
                <div class="d-flex">
                  <v-list-item-icon>
                    <v-icon size="42">mdi-file</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.source_document }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.document_type }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </div>
                <div class="d-flex flex-wrap">
                  <v-chip small color="primary" class="me-1 mb-1" v-if="item.area_code !== '' && item.area_code != null">Area Code: {{ item.area_code }}</v-chip>
                  <v-chip small color="primary" class="me-1 mb-1" v-if="item.dept_code !== '' && item.dept_code != null">Dept Code: {{ item.dept_code }}</v-chip>
                  <v-chip small color="primary" class="me-1 mb-1" v-if="item.div_code !== '' && item.div_code != null">Div Code: {{ item.div_code }}</v-chip>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="createDocument()" :disabled="selectedDocument == null" :loading="loading">Create Document</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      loading: false,
      dialog: false,
      selectedDocument: null,
      approval_templates: [],
    };
  },

  computed: {
    items() {
      if (this.search == "") return this.approval_templates;

      var res = this.approval_templates.filter((item) => {
        return (
          item.document_type
            .toString()
            .toLowerCase()
            .includes(this.search.toString().toLowerCase()) ||
          item.description
            .toString()
            .toLowerCase()
            .includes(this.search.toString().toLowerCase()) ||
          item.area_code
            .toString()
            .toLowerCase()
            .includes(this.search.toString().toLowerCase())
        );
      });
      return res;
    },
  },

  methods: {
    async getData(refresh=false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "mjo/approval-templates");

      await this.$axios.get("mjo/approval-templates").then((res) => {
        this.approval_templates = res.data.data.approval_templates;
      });
    },

    async createDocument() {
      if (typeof this.approval_templates[this.selectedDocument] === "undefined")
        return;

      this.loading = true;
      this.showLoadingOverlay(true);

      var d = this.approval_templates[this.selectedDocument];
      var formData = new FormData();
      formData.append("approval_template_id", d.id);
      await this.$axios
        .post("mjo/create-document", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.redirect("Administration.MJO.Edit", { id: resData.data.id });
          }
          this.loading = false;
          this.showLoadingOverlay(false);
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
          this.showLoadingOverlay(false);
        });
    },
  },

  mounted() {
    // this.getData();
  },
};
</script>
