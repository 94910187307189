<template>
  <div>
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->

    <app-dialog v-model="dialog" :title="mjo_header.document_no_" :loading="loading" class="dialogdetail" max-width="1400">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>

        <app-log-data-dialog module_name="mjo" :id="mjo_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>

        <v-divider vertical class="mx-2"></v-divider>

        <app-button :disabled="!enableEditBtn" @click="redirect('Administration.MJO.Edit', { id: id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
        <app-button :disabled="!enableUpdateBtn" @click="redirect('Administration.MJO.Update', { id: id })" title="Update" mdi-icon="mdi-file-document-edit-outline"></app-button>
        <app-button :disabled="!enableDeleteBtn" @click="deleteData()" :loading="loading_delete" title="Delete Document" mdi-icon="mdi-delete"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!enableSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval" title="Send Approval" mdi-icon="mdi-file-send"></app-button>
        <app-button :disabled="!enableApproveBtn" @click="approveData()" :loading="loading_approve" title="Approve Document" mdi-icon="mdi-file-check"></app-button>
        <app-button :disabled="!enableReceiveBtn" @click="receiveData()" :loading="loading_receive" title="Receive Document" mdi-icon="mdi-checkbox-marked-outline"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!enableCancelBtn" @click="cancelData()" :loading="loading_cancel" title="Cancel Document" mdi-icon="mdi-file-document-remove"></app-button>
        <app-button :disabled="!enableReopenBtn" @click="reopenData()" :loading="loading_reopen" title="Re-Open Document" mdi-icon="mdi-file-restore"></app-button>
        <app-button :disabled="!enableCloseBtn" @click="closeData()" :loading="loading_close_document" title="Closed Document" mdi-icon="mdi-book-check"></app-button>
      </template>

      <v-row>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <card-expansion title="General">
                <!-- <v-sheet elevation="1" class="pa-0"> -->
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field label="Document No." v-model="mjo_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Date" v-model="mjo_header.document_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Type" v-model="mjo_header.document_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Company ID" v-model="mjo_header.company_id" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Area Code" v-model="mjo_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Department" v-model="mjo_header.dept_name" hide-details outlined dense class="mb-2" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Created By" v-model="mjo_header.created_by_username" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Created Date" v-model="mjo_header.created_date" hide-details outlined dense class="mb-2" readonly type="datetime-local"></v-text-field>
                    <v-text-field label="Status" v-model="mjo_header.status" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Prioritas" v-model="mjo_header.priority" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Tipe Pekerjaan" v-model="mjo_header.job_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Tanggal Rusak" v-model="mjo_header.damage_at" hide-details outlined dense class="mb-2" readonly type="datetime-local"></v-text-field>
                  </v-col>
                </v-row>
                <v-btn color="light" elevation="1" v-if="!loading" @click="redirect('Administration.MJO.Detail', { id: id })">View Detail</v-btn>
                <!-- </v-sheet> -->
              </card-expansion>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <card-expansion :hide-title="true">
                <template #title>
                  <v-tabs v-model="tab2">
                    <v-tab>Fixed Asset</v-tab>
                    <v-tab>Approval</v-tab>
                  </v-tabs>
                </template>
                <v-tabs-items v-model="tab2">
                  <v-tab-item class="pa-1">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field label="FA No." v-model="mjo_header.fa_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Company ID" v-model="mjo_header.fa_company_id" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Description" v-model="mjo_header.fa_description" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Description 2" v-model="mjo_header.fa_description2" hide-details outlined dense class="mb-2" readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field label="Area Code" v-model="mjo_header.fa_area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Location Code" v-model="mjo_header.fa_location_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Responsibility" v-model="mjo_header.fa_responsibility" hide-details outlined dense class="mb-2" readonly></v-text-field>
                        <v-text-field label="Serial No." v-model="mjo_header.fa_serial_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="pa-1">
                    <ApprovalList v-bind:data-list="mjo_approval" elevation="1"></ApprovalList>
                  </v-tab-item>
                </v-tabs-items>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <CommentList :mjo_header_id="id"></CommentList>
        </v-col>
      </v-row>
    </app-dialog>


  </div>
</template>

<script>
import CommentList from "./CommentList";
import ApprovalList from "./ApprovalList.vue";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CommentList,
    ApprovalList,
  },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      loading_cancel: false,
      loading_attachment: false,
      dialog: false,
      tab2: null,
      mjo_header: {},
      mjo_approval: [],
      mjo_comment: [],
      mjo_pic: [],
    };
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
  },

  computed: {
    enableDeleteBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "approved"])) return false;

      if (this.mjo_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("mjo", "delete") && !this.inArray(this.mjo_header.status, ["approved"]) && this.inArray(this.mjo_header.status, ["open", "cancel"])) {
        return true;
      }
      return false;
    },
    enableReopenBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel", "open"])) return false;

      if (this.mjo_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("mjo", "reopen") && !this.inArray(this.mjo_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    enableCancelBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "cancel") && !this.inArray(this.mjo_header.status, ["approved"]) && !this.inArray(this.mjo_header.status, ["cancel"])) {
        return true;
      }
      return false;
    },
    enableSendApprovalBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "send-approval") && this.mjo_header.status == "open") {
        return true;
      }
      return false;
    },
    enableApproveBtn() {
      if (!this.inArray(this.mjo_header.status, ["waiting-approval"])) return false;

      if (this.mjo_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.mjo_approval !== "undefined") {
          if (this.mjo_approval.length >= 0) {
            this.mjo_approval.forEach((item) => {
              let approved = item.approved
              if (!approved) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    enableEditBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "modify") && this.mjo_header.status == "open") {
        return true;
      }
      return false;
    },
    enableUpdateBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "update") && this.mjo_header.status == "approved" && (this.mjo_header.substatus == "received" || this.mjo_header.substatus == "on-progress")) {
        return true;
      }
      return false;
    },
    enableCloseBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (!this.inArray(this.mjo_header.substatus, ["", "on-process"])) return false;

      if (this.modulePermission("mjo", "close")) {
        return true;
      }

      return false;
    },
    enableSetPicBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (this.modulePermission("mjo", "set-pic") && this.mjo_header.status == "approved") {
        return true;
      }
      return false;
    },
  },

  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    showDialog() {
      this.dialog = true;
      this.getDetail(true);
    },

    async getDetail(refresh = false) {
      this.loading = true;

      this.mjo_header = {};
      this.mjo_approval = [];
      this.mjo_comment = [];
      this.user = {};

      if (refresh == true) this.AxiosStorageRemove("GET", "mjo/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("mjo/detail", config)
        .then((res) => {
          this.mjo_header = res.data.data.mjo_header;
          this.mjo_approval = res.data.data.mjo_approval;
          this.mjo_comment = res.data.data.mjo_comment;
          this.mjo_pic = res.data.data.mjo_pic;
          this.user = res.data.data.user;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    /**
     * deleteData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Cancel/Open
     */
    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("mjo/delete/" + this.mjo_header.id)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.dialog = false;
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete document?", post);
    },

    /**
     * cancelData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    cancelData() {
      let post = async () => {
        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.dialog = false;
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * sendApproval
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    sendApproval() {
      let post = async () => {
        this.loading_send_approval = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/send-approval", formData)
          .then((res) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.dialog = false;
          })
          .catch((error) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Send Approval?", post);
    },

    /**
     * approveData
     * Method untuk meng-approved dokumen
     * Dokumen harus berstatus Waiting Approval
     */
    approveData() {
      let post = async () => {
        this.loading_approve = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/approve", formData)
          .then((res) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Approve document?", post);
    },

    /**
     * reopenData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    reopenData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/reopen", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Re-open document?", post);
    },
  }
};
</script>
