<template>
  <div>
    <v-container>
      <page-title title="Maintenance Job Order">
        <template slot="action">
          <approval-template v-if="modulePermission('mjo', 'create')"></approval-template>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-tabs v-model="tab">
              <v-tab>My List</v-tab>
              <v-tab v-if="modulePermission('mjo', 'approve')">
                <v-badge dot v-if="badgeMyApproval">My Approval</v-badge>
                <span v-else>My Approval</span>
              </v-tab>
              <v-tab v-if="modulePermission('mjo', 'general_affair')">
                <span>General Affair</span>
              </v-tab>
              <v-tab v-if="modulePermission('mjo', 'plan_facility')">
                <span>Plan Facility</span>
              </v-tab>
              <v-tab v-if="modulePermission('mjo', 'general_affair')">
                <span>List Asset</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ListData></ListData>
              </v-tab-item>
              <v-tab-item v-if="modulePermission('mjo', 'approve')">
                <MyApproval ref="refMyApproval"></MyApproval>
              </v-tab-item>
              <v-tab-item v-if="modulePermission('mjo', 'general_affair')">
                <ListDataGA></ListDataGA>
              </v-tab-item>
              <v-tab-item v-if="modulePermission('mjo', 'plan_facility')">
                <ListDataPF></ListDataPF>
              </v-tab-item>
              <v-tab-item v-if="modulePermission('mjo', 'general_affair')">
                <ListAsset></ListAsset>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
import ListDataGA from "./components/ListDataGA.vue";
import ListDataPF from "./components/ListDataPF.vue";
import ListAsset from "./components/ListAsset.vue";
import ApprovalTemplate from "./components/ApprovalTemplate.vue";
import MyApproval from "./components/MyApproval.vue";
export default {
  components: {
    ListData,
    ListDataGA,
    ListDataPF,
    ApprovalTemplate,
    MyApproval,
    ListAsset,
  },
  data() {
    return {
      tab: null,

      dialog: null,
      approval_templates: [],

      badgeMyApproval: false,
      badgeMyTask: false,
    };
  },
  methods: {
    add() {
      this.dialog = true;
    },

    async showBadgeMyApproval(refresh) {
      const uri = "mjo/notif-exists";
      this.badgeMyApproval = false;
      this.badgeMyTask = false;

      if (refresh) this.AxiosStorageRemove("GET", uri);

      await this.$axios
        .get(uri, {
          sessionStorage: !refresh,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.badgeMyApproval = resData.data.approval;
            this.badgeMyTask = resData.data.task;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);

          this.badgeMyApproval = false;
          this.badgeMyTask = false;
        });
    },
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("mjo", "view", true);

    this.showBadgeMyApproval();
  },
};
</script>